import React, { useState, useEffect, useCallback, useContext } from 'react'
import { IconContext } from 'react-icons' //IconContextをインポート
import { BiCloudDownload } from "react-icons/bi";
import axios from "axios";
import loading_img from '../img/loading.gif';
import { ApiContext } from "../context/ApiContext";

/*
//リンクを作成
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download = 'movie.mp4';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
*/


const Preview = () => {
    // 
    const { loading, setLoading } = useContext(ApiContext);
    const [select, setSelect] = useState("1");
    const [imgPathList, setImgPathList,] = useState([20]);
    const save = async () => {
        var token = '15f1867c30067b4e5913f72322a0823afde4a48d'
        const uploadData = new FormData();
        uploadData.append("author", 5);
        var body = document.getElementById('profile').value
        uploadData.append("body", body);

        const res = await axios.patch('https://tictac-clip.com/api/town/1/', uploadData, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });


        alert('保存しました')
    }

    useEffect(() => {
        //10秒に一回データを取得
        const interval = setInterval(() => {
            //getAll()
        }, 60000);
        getAll()
        //loadTown();
        setLoading(false)
    }, []);
    const getAll = () => {
        getImg(1)
        getImg(2)
        getImg(3)
        getImg(4)
        getImg(5)
        getImg(6)
        getImg(7)
        getImg(8)
        getImg(9)
        getImg(10)
        getImg(11)
        getImg(12)
        getImg(13)
        getImg(14)
        getImg(15)
        getImg(16)
        getImg(17)
        getImg(18)
    }

    const now_data = () => {

    }
    const now_csv = async () => {
        setLoading(true);
        const cal_day11 = document.getElementById('cal_day11').value;
    
        // 日付を20240401形式に変換
        const date = new Date(cal_day11);
        const dayFormat =
            date.getFullYear() +
            ('00' + (date.getMonth() + 1)).slice(-2) +
            ('00' + date.getDate()).slice(-2);
    
        const initializeUrl = `https://tictac-clip.com/api/initialize_getMov/?folder=${select}&day=${dayFormat}`;
        const processUrl = `https://tictac-clip.com/api/process_getMov/?day=${dayFormat}&chunk_size=10`;
        const finalizeUrl = `https://tictac-clip.com/api/finalize_getMov/?day=${dayFormat}`;
    
        try {
            // 初期化
            const initResponse = await axios.get(initializeUrl);
            console.log("初期化成功:", initResponse.data);
    
            // チャンク処理
            let isCompleted = false;
            while (!isCompleted) {
                const processResponse = await axios.get(processUrl);
                console.log("チャンク処理中:", processResponse.data);
                isCompleted = processResponse.data.is_completed;
            }
    
            // 完了処理
            const finalizeResponse = await axios.get(finalizeUrl);
            console.log("処理完了:", finalizeResponse.data);
    
            // 動画のダウンロードリンクを生成
            const downloadURL = finalizeResponse.data.video_path.replace('/home/hagumi', 'https://tictac-clip.com');
            zipDownload(downloadURL);
            console.log(downloadURL)
            
        } catch (err) {
            console.error("エラー:", err);
            alert("エラーが発生しました。もう一度お試しください。");
        } finally {
            setLoading(false);
        }
    };
    const zipDownload = (zip) => {
        const url = "https://tictac-clip.com/api/zipDownload/?folder=" + zip
  
        // ヘッダーを定義
       

        axios.get(url)
            .then(res => {
                const downloadURL = "https://tictac-clip.com/media/api/file/video.zip"
                console.log(downloadURL)
                //リンクを作成
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download = 'movie.zip';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false)
                alert("ダウンロードを開始しました。しばらくお待ちください。")
            })
            .catch(err => {
                console.log(err)
                alert("エラーが発生しました。もう一度お試しください。")
                setLoading(false)
            })
    }

    const getImg = (id) => {

        let tempList = imgPathList
        const url = "https://tictac-clip.com/api/get_img/?folder=" + id
        axios.get(url)
            .then(res => {
                //console.log(res.data)
                let img = res.data

                img = "https://tictac-clip.com" + img.replace('/home/hagumi', '')
                console.log(img)
                //img = "https://tictac-clip.com/media/api/file/2/20240329045549.jpg"
                setImgPathList(prevList => {
                    const newList = [...prevList]; // 既存のリストを複製
                    newList[parseInt(id)] = img; // 新しい画像パスを設定
                    return newList; // 更新されたリストを返す
                });

            })
            .catch(err => {
                const img = "https://tictac-clip.com/media/api/file/noimg.png"
                setImgPathList(prevList => {
                    const newList = [...prevList]; // 既存のリストを複製
                    newList[parseInt(id)] = img; // 新しい画像パスを設定
                    return newList; // 更新されたリストを返す
                });
            })

        setImgPathList(tempList)

    }
    const selectSpot = (event) => {
        setSelect(event.target.value);
    }

    // USING AJAX TO UPDATE DATABASE THROUGH REST API
const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // jQuery.trim は不要なため、標準の trim() を使用
            if (cookie.startsWith(`${name}=`)) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};

    return (
        <div className='profileBlock'>
            <div className='Calendar_block'>
                <div className='cal_form'>
                    <input id="cal_day11" type="date" name="example1" ></input>


                    {/*セレクトボックス*/}
                    <select className='timeline_name' name="example1" value={select} onChange={selectSpot}>
                        <option value="1">本町山脇邸前</option>
                        <option value="2">本町中央</option>
                        <option value="3">帝人通り</option>
                        <option value="4">キオラスクエア</option>
                        <option value="5">駅前商店街通り</option>
                        <option value="6">三原港港湾ビル前</option>
                        <option value="7">三原港物揚げ場</option>
                        <option value="8">三原小学校バス停前</option>
                        <option value="9">ファミリーマート前道路</option>
                        <option value="10">旧勝村建材前道路</option>
                        <option value="11">花本時計店交差点</option>
                        <option value="12">ペアシティ南側</option>
                        <option value="13">グーデビル前道路</option>
                        <option value="14">エスポ前交差点</option>
                        <option value="15">リージョン前道路</option>
                        <option value="16">フジグラン前道路</option>
                        <option value="17">駅前タッチパネル</option>
                        <option value="18">港前タッチパネル</option>
                    </select>
                    <button className='btn' id='setting2' onClick={now_csv}>
                        <IconContext.Provider value={{ color: '#FFF', size: '40px', className: 'shdow' }}>
                            <BiCloudDownload />
                        </IconContext.Provider>
                    </button>
                </div>

            </div>
            <div className='img_box'>
                <div className='photo'>
                    <p>本町山脇邸前</p>
                    {imgPathList[1] && <img src={imgPathList[1]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>本町中央</p>
                    {imgPathList[2] && <img src={imgPathList[2]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>帝人通り</p>
                    {imgPathList[3] && <img src={imgPathList[3]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>キオラスクエア</p>
                    {<img src={imgPathList[4]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>駅前商店街通り</p>
                    {<img src={imgPathList[5]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>三原港港湾ビル前</p>
                    {<img src={imgPathList[6]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>三原港物揚げ場</p>
                    {<img src={imgPathList[7]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>三原小学校バス停前</p>
                    {<img src={imgPathList[8]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>ファミリーマート前道路</p>
                    {<img src={imgPathList[9]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>旧勝村建材前道路</p>
                    {<img src={imgPathList[10]} alt="preview" className='thum' />}
                </div>

                <div className='photo'>
                    <p>花本時計店交差点</p>
                    {<img src={imgPathList[11]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>ペアシティ南側</p>
                    {<img src={imgPathList[12]} alt="preview" className='thum' />}
                </div>

                <div className='photo'>
                    <p>グーデビル前道路</p>
                    {<img src={imgPathList[13]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>エスポ前交差点</p>
                    {<img src={imgPathList[14]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>リージョン前道路</p>
                    {<img src={imgPathList[15]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>フジグラン前道路</p>
                    {<img src={imgPathList[16]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>駅前タッチパネル</p>
                    {<img src={imgPathList[17]} alt="preview" className='thum' />}
                </div>
                <div className='photo'>
                    <p>港前タッチパネル</p>
                    {<img src={imgPathList[18]} alt="preview" className='thum' />}
                </div>




            </div>

            {loading && <div className='loading'><img src={loading_img} alt="" /></div>}
        </div>
    )
}

export default Preview